.company_Container {
  padding-top: 6rem;
  /* padding-bottom: 5rem; */
}

.company_Wrapper {
  width: 85%;
  margin: 0 auto;
}

.company_Mission {
  display: flex;
  flex-direction: row;
  gap: 8rem;
}

.company_Mission h2 {
  font-size: 2rem;
  text-align: center;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  margin-top: 2rem;
  width: 50%;
}

.company_Text p {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  line-height: 28px;
  margin-top: 2rem;
}

.company_Text:last-child {
  margin-bottom: 3rem;
}

@media screen and (max-width: 1440px) {
  .company_Wrapper {
    width: 95%;
  }
}

@media screen and (max-width: 770px) {
  .company_Mission {
    gap: 1.4rem;
  }

  .company_Mission h2 {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 500px) {
  .company_Mission {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .company_Mission h2 {
    text-align: left;
    margin-top: 0.709rem;
  }
}

.value_Container {
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 3rem;
}

.value_Container h2 {
  font-size: 2.7rem;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 10px;
  line-height: 45px;
  text-align: center;
  font-weight: 400;
}

.value_Container hr {
  background: #7b3f00;
  height: 5px;
  width: 10%;
  margin: 0 auto;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.value_Wrapper {
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  line-height: 30px;
  flex-wrap: wrap;
  margin-top: 7rem;
}

.value_Wrapper .value_Box {
  border: 1px solid #fafafa;
  padding: 3rem;
  width: 22%;
  border-radius: 5px;
  transition: transform 0.73s ease-out;
  transition: 0.5s ease-in-out;
}

.value_Wrapper .value_Box:hover {
  border: 1px solid #e5eaed;
  box-shadow: 0 4px 8px #e5eaed;
  transform: translateY(-13px);
}

.value_Box .icon {
  color: #7b3f00;
  font-size: 3rem;
  width: 100%;
  margin-bottom: 1rem;
  opacity: 0.9;
}

.value_Box p {
  color: #000;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  line-height: 30px;
  text-align: center;
  font-weight: 700;
}

@media screen and (max-width: 1440px) {
  .value_Wrapper {
    width: 95%;
    flex-wrap: wrap;
    flex-grow: 1;
  }
}

@media screen and (max-width: 880px) {
  .value_Wrapper .value_Box {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 770px) {
  .value_Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3rem;
  }

  .value_Wrapper .value_Box {
    margin: 0 auto;
    margin-top: 2rem;
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .value_Container h2 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .value_Container {
    padding-top: 3rem;
  }

  .value_Wrapper .value_Box {
    width: 88%;
    margin-top: 1.7rem;
  }
}

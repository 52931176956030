.button {
  background: #7b3f00;
  border: none;
  padding: 13px 30px;
  border-radius: 10px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.button:hover {
  opacity: 0.8;
}

a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
}

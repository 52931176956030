.why_Container {
  width: 100%;
  margin-top: 8rem;
  margin-bottom: 5rem;
}

.why_Wrapper {
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  gap: 5rem;
  align-items: center;
}

.why_Text {
  width: 50%;
}

.why_Text h2 {
  font-size: 2.7rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.why_Text hr {
  background: #7b3f00;
  height: 5px;
  width: 10%;
  margin-top: 1.5rem;
}

.why_Text p {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  line-height: 25px;
  margin-top: 2rem;
}

.why_Img {
  width: 50%;
}

img {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .why_Wrapper {
    width: 95%;
  }
}

@media screen and (max-width: 1070px) {
  .why_Wrapper {
    gap: 3rem;
  }

  .why_Text hr {
    margin-top: 1rem;
  }

  .why_Text p {
    margin-top: 1rem;
  }

  .why_Img {
    width: 75%;
  }
}

@media screen and (max-width: 770px) {
  .why_Wrapper {
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    /* gap: 3rem; */
  }

  .why_Text {
    width: 95%;
    margin: 0 auto;
  }

  .why_Img {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .why_Text h2 {
    font-size: 2rem !important;
    line-height: 1.2353;
  }

  .why_Text p {
    font-size: 0.919rem;
  }

  .why_Text hr {
    width: 20%;
    height: 3px;
    margin-top: 1rem;
  }
}

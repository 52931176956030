.content_Container {
  width: 100%;
  padding-bottom: 5rem;
  background-color: #fafafa;
}

.content_Content,
.content_Cont {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 85%;
  margin: 0 auto;
  /* text-align: center; */
}

.content_Img {
  /* border: 1px solid #f5f5; */
  padding-top: 2rem;
  margin-top: 6rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid #cccc;
  width: 40%;
}

.content_Img h4 {
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  line-height: 35px;
}

.content_Img h3 {
  font-family: "Montserrat", sans-serif;
  line-height: 25px;
  font-size: 0.989rem;
  margin-bottom: 1rem;
}

.content_Img p {
  font-family: "Montserrat", sans-serif;
  line-height: 25px;
  font-size: 0.789rem;
}

.content_Cont {
  margin-top: 4rem;
}

@media screen and (max-width: 900px) {
  .content_Content,
  .content_Cont {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 500px) {
  .content_Content,
  .content_Cont {
    width: 100%;
  }

  .content_Img {
    width: 100%;
  }

  .content_Img h4 {
    line-height: 15px;
    margin-top: 16px;
  }
}

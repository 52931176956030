.hero_Service {
  width: 100%;
  background-image: url(../../../Images/servicebg.jpg);
  height: 70vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #524d4d;
  background-attachment: fixed;
  background-blend-mode: multiply;
  cursor: pointer;
  transition: all 0.6s ease-out;
}

.hero_Wrapper {
  width: 85%;
  margin: 0 auto;
  padding-top: 18rem;
}

.hero_Wrapper h5 {
  color: #7b3f00;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  line-height: 36px;
}

.hero_Wrapper h2 {
  color: #fff;
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 10px;
  line-height: 45px;
}

.hero_Wrapper p {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  width: 50%;
  line-height: 25px;
}

@media screen and (max-width: 1440px) {
  .hero_Wrapper {
    width: 95%;
  }
}

@media screen and (max-width: 1330px) {
  .hero_Wrapper {
    padding-top: 12rem;
  }
}

@media screen and (max-width: 770px) {
  .hero_Wrapper {
    padding-top: 14rem;
  }

  .hero_Wrapper p {
    width: 85%;
  }
}

@media screen and (max-width: 600px) {
  .hero_Wrapper {
    padding-top: 14rem;
  }

  .hero_Wrapper h2 {
    font-size: 1.8rem !important;
    line-height: 1.2353;
  }

  .hero_Wrapper p {
    font-size: 0.919rem;
  }
}

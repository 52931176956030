.mission_Container {
  width: 100%;
  background-color: #000;
  margin-top: 8rem;
  margin-bottom: 5rem;
}

.mission_Wrapper {
  width: 85%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  gap: 5rem;
}

.mission_Text {
  width: 50%;
}

.mission_Text h2 {
  color: #fff;
  font-size: 2.7rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.mission_Text hr {
  background: #7b3f00;
  height: 5px;
  width: 10%;
  /* margin: 0 auto; */
  align-items: center;
  text-align: center;
  justify-content: center;
  border: none;
  margin-top: 1.5rem;
}

.mission_Text p {
  color: #fff;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  line-height: 30px;
  margin-top: 2rem;
}

.img {
  width: 50%;
}

.img img {
  width: 100%;
}

.mission_Text button {
  background: transparent;
  border: none;
  padding: 13px 40px;
  border-radius: 10px;
  margin-top: 3rem;
  border: none;
  border: 1px solid #7b3f00;
  transition: all 0.5s ease-in-out;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}

.mission_Text button:hover {
  background: #7b3f00;
}

.mission_Text button a {
  text-decoration: none;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
}

@media screen and (max-width: 1440px) {
  .mission_Wrapper {
    width: 95%;
  }
}

@media screen and (max-width: 1070px) {
  .mission_Wrapper {
    padding-top: 6rem;
    padding-bottom: 6rem;
    gap: 3rem;
  }

  .mission_Text button {
    margin-top: 1rem;
  }

  .mission_Text p {
    margin-top: 1rem;
  }

  .mission_Text hr {
    margin-top: 1rem;
  }

  .img {
    width: 70%;
  }
}

@media screen and (max-width: 770px) {
  .mission_Wrapper {
    flex-wrap: wrap;
    gap: 3rem;
    width: 100%;
  }

  .mission_Text {
    width: 95%;
    margin: 0 auto;
  }

  .img {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .mission_Text h2 {
    font-size: 2rem !important;
    line-height: 1.2353;
  }

  .mission_Text p {
    font-size: 0.919rem;
  }

  .mission_Text button {
    font-size: 0.919rem;
  }

  .mission_Text hr {
    width: 20%;
    height: 3px;
    margin-top: 1rem;
  }
}

.header_Container {
  width: 100%;
}

.copyright {
  background: #7b3f00;
  opacity: 0.8;
  padding-top: 0.609rem;
  padding-bottom: 0.609rem;
}

.copyright .rights {
  width: 87%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 2rem;
}

.copyright .icon {
  align-items: center;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  letter-spacing: 1px;
  color: #fff;
}

.header_Wrapper {
  display: flex;
  flex-direction: row;
  width: 85%;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid rgb(218, 213, 213);
  border-radius: 5px;
  border-top: none !important;
  box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.42);
}

.hamburger {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  display: none;
  outline: none;
}

.hamburger .horizontal {
  width: 35px;
  height: 3px;
  margin: 3px 0px;
  background-color: black;
}

.activeSide_Nav {
  background-color: #7b3f00;
  /* display: none; */

  transition: transform 0.5s ease-in 0.4s;
  animation: slide-in 0.2s ease-in-out;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.activeSide_Nav ul li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px 0px;
}

.activeSide_Nav a {
  color: white;
  align-items: center;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  letter-spacing: 1px;
}

.show_Nav {
  display: none;
}

@media screen and (max-width: 1440px) {
  .header_Wrapper {
    width: 95%;
  }
}

@media screen and (max-width: 1070px) {
  .header_Wrapper .burger_Menu {
    display: flex;
    flex-direction: column;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    display: flex;
    outline: none;
    /* background-color: red; */
  }

  .hamburger .horizontal {
    width: 35px;
    height: 3px;
    margin: 3px 0px;
    background-color: black !important;
    /* opacity: 0.2; */
    /* color: ; */
  }
}

@media screen and (max-width: 770px) {
  .hamburger span {
    background-color: #000;
  }

  .header_Wrapper {
    box-shadow: none;
  }
}

@media screen and (max-width: 600px) {
  .header_Wrapper {
    border: none;
    box-shadow: none;
    padding-left: 0;
  }
}

.nav_Blog {
  width: 100%;
  padding-top: 5.5rem;
  padding-bottom: 5rem;
}

.nav_Blog .nav_Text {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
}

.nav_Blog .nav_Text {
  list-style: none;
  margin-bottom: 1.5rem;
}

.nav_Blog p {
  color: #000;
  font-size: 0.75rem;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  opacity: 0.8;
}

.leader_Container {
  width: 100%;
  margin-bottom: 8rem;
  margin-top: 8rem;
}

.leader_Wrapper {
  text-align: center;
  align-items: center;
}

.leader_Wrapper h5 {
  text-align: center;
  color: #7b3f00;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  line-height: 45px;
}

.leader_Wrapper h2 {
  color: #000;
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 15px;
  line-height: 65px;
  font-weight: 500;
}

.leader_Image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 3rem;
  gap: 6rem;
}

.leader_Image h4 {
  color: #000;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  /* padding-bottom: 15px; */
  line-height: 25px;
  font-weight: 500;
  margin-top: 20px;
}

.leader_Image p {
  font-family: "Montserrat", sans-serif;
  line-height: 28px;
  font-size: 0.789rem;
}

.button {
  background: transparent;
  border: none;
  padding: 13px 30px;
  border-radius: 10px;
  color: #000;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  border: 1px solid #7b3f00;
  margin-top: 1.5rem;
}

.button:hover {
  opacity: 0.8;
  background: #7b3f00;
  color: #fff;
}

@media screen and (max-width: 600px) {
  .leader_Container {
    margin-top: 5rem;
  }

  .leader_Image {
    gap: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .leader_Image {
    flex-direction: column;
    gap: 3rem;
  }

  .leader_Image img {
    width: 60%;
  }
}

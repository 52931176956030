.logo {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  font-size: 1.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.logo img {
  width: 10%;
}

.logo p {
  color: #7b3f00;
  /* margin-top: 30px; */
}
@media screen and (max-width: 1220px) {
  .logo img {
    width: 45px;
  }

  .logo {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .logo p {
    font-size: 16px;
  }
}

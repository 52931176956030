.nav_Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav_Container ul {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav_Container ul li {
  list-style: none;
}

.nav_Container ul li a {
  text-decoration: none;
  margin: 30px;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}

.show_Nav {
  display: none;
}

@media screen and (max-width: 1070px) {
  .nav_Container {
    display: none;
  }
}

.service_Container {
  width: 100%;
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.service_Wrapper {
  width: 85%;
  margin: 0 auto;
}

.service_Wrapper h2 {
  font-size: 2.5rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.service_Wrapper hr {
  background: #7b3f00;
  height: 5px;
  width: 10%;
  margin: 0 auto;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 1.5rem;
}

.service_Cards {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  margin-top: 5rem;
}

.service_Cards .card {
  text-align: center;
  background-color: #fafafa;
  padding-top: 4rem;
  padding-bottom: 6rem;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 5px;
  border: 1px solid #e5eaed;
  box-shadow: 0 4px 8px #e5eaed;
  transition: transform 0.3s ease-out;
  width: 70%;
  word-wrap: break-word;
}

.service_Cards .card:hover {
  transform: translateY(-13px);
  transition: transform 0.3s ease-out;
}

.icon {
  font-size: 5rem;
  /* background: #7b3f00; */
  color: #7b3f00;
  margin-bottom: 1rem;
}

.card h4 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 1rem;
  font-family: "Montserrat", sans-serif;
}

.card p {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  line-height: 28px;
}

@media screen and (max-width: 1440px) {
  .service_Wrapper {
    width: 95%;
  }
}

@media screen and (max-width: 1070px) {
  .service_Cards {
    gap: 1.1rem;
  }

  .service_Cards .card {
    padding-top: 3rem;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 770px) {
  .service_Cards {
    flex-wrap: wrap;
    gap: 3rem;
  }

  .service_Cards .card {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 600px) {
  .service_Cards {
    gap: 1.5rem;
  }

  .service_Cards .card {
    width: 95%;
    margin: 0 auto;
  }

  .service_Wrapper h2 {
    font-size: 2rem !important;
    line-height: 1.2353;
  }

  .service_Wrapper hr {
    width: 20%;
    height: 3px;
    margin-top: 1rem;
  }

  .card h4 {
    font-size: 1.2rem;
  }

  .card p {
    font-size: 0.919rem;
  }
}

.what_Container {
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.what_Wrapper {
  width: 60%;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.what_Wrapper h2 {
  font-size: 2.7rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.what_Wrapper hr {
  background: #7b3f00;
  height: 5px;
  width: 10%;
  margin: 0 auto;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 1.5rem;
}

.what_Wrapper p {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  line-height: 28px;
  margin-top: 2rem;
}

.what_Wrapper button {
  background: #7b3f00;
  border: none;
  padding: 16px 35px;
  border-radius: 10px;
  margin-top: 2rem;
  color: #fff;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.what_Wrapper button:hover {
  opacity: 0.8;
}

.what_Wrapper button a {
  text-decoration: none;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
}

@media screen and (max-width: 1440px) {
  .what_Wrapper {
    width: 75%;
  }
}

@media screen and (max-width: 770px) {
  .what_Wrapper {
    width: 85%;
  }
}

@media screen and (max-width: 600px) {
  .what_Wrapper {
    width: 90%;
  }

  .what_Wrapper h2 {
    font-size: 2rem !important;
    line-height: 1.2353;
  }

  .what_Wrapper p {
    font-size: 0.919rem;
  }

  .what_Wrapper button {
    font-size: 0.919rem;
  }

  .what_Wrapper hr {
    width: 20%;
    height: 3px;
    margin-top: 1rem;
  }
}

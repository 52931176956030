.solid_Content {
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 8rem;
}

.contents {
  width: 50%;
  margin: 0 auto;
}

.contents h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  line-height: 54px;
  margin-top: 3rem;
}

.contents p {
  font-family: "Montserrat", sans-serif;
  line-height: 30px;
}

@media screen and (max-width: 768px) {
  .solid_Content {
    margin-top: 1rem;
  }

  .contents {
    width: 95%;
  }
}

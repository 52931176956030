* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media screen and (max-width: 1040px) {
  * {
    overflow-x: hidden;
  }
}

.partner_Container {
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 3rem;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.partner_Container h2 {
  font-size: 2rem;
  text-align: center;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.partner_Container hr {
  background: #7b3f00;
  height: 5px;
  width: 10%;
  margin: 0 auto;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.partner_Wrapper {
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 6rem;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

@media screen and (max-width: 1440px) {
  .partner_Wrapper {
    width: 95%;
  }
}

@media screen and (max-width: 880px) {
  .partner_Wrapper {
    justify-content: center;
    gap: 7rem;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 770px) {
  .partner_Wrapper {
    gap: 6rem;
  }
}

@media screen and (max-width: 600px) {
  .partner_Container h2 {
    font-size: 1.8rem !important;
    line-height: 1.2353;
  }

  .partner_Container hr {
    width: 20%;
    height: 3px;
    margin-top: 1rem;
  }

  .partner_Wrapper {
    gap: 2rem;
    margin: 0 auto;
    margin-top: 4rem;
  }

  .partner_Wrapper .img {
    width: 30%;
    gap: 4rem;
  }
  /* .partner_Wrapper {
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
  } */
}

.footer_News {
  width: 100%;
  background: #7b3f00;
  padding-top: 8rem;
  padding-bottom: 5rem;
  text-align: center;
  border-bottom: 1px solid rgb(28, 27, 27);
}

.footer_News h4 {
  font-size: 1.7rem;
  margin-bottom: 2rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #fff;
}

.footer_News input {
  padding: 15px 10px;
  width: 30%;
  border: none;
  margin-right: 3px;
  font-family: "Montserrat", sans-serif;
}

.footer_News button {
  background: #e39608;
  border: none;
  padding: 13px 30px;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  cursor: pointer;
  color: #fff;
  transition: all 0.5s ease-in-out;
}

.footer_News button:hover {
  opacity: 0.8;
}

.footer_News button a {
  text-decoration: none;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
}

.footer {
  width: 100%;
  background: #7b3f00;
  padding-top: 5rem;
  padding-bottom: 8rem;
}

.footer .foot {
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.foot h2 {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  width: 30%;
}

.foot .address h4 {
  font-size: 1rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #fff;
}

.foot .address p {
  font-size: 0.87rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  margin-top: 1rem;
  opacity: 0.6;
}

.foot .socials {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 1.5rem;
}

.foot .socials p {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.foot .socials a {
  color: #fff;
  font-size: 1.6rem;
}

span {
  background-color: #676464;
  height: 5rem;
  width: 1px;
}

.copyright {
  background: #7b3f00;
  opacity: 0.8;
  padding-top: 0.789rem;
  padding-bottom: 0.789rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  justify-content: center;
}

.copyright p {
  align-items: center;
  text-align: center;
  opacity: 0.8;
  font-family: "Montserrat", sans-serif;
  font-size: 0.789rem;
  letter-spacing: 1px;
  color: #fff;
}

@media screen and (max-width: 1440px) {
  .footer .foot {
    width: 95%;
  }
}

@media screen and (max-width: 770px) {
  .footer_News input {
    width: 80%;
    margin: 0 auto;
    border-radius: 5px;
    margin-bottom: 0.489rem;
    padding: 20px 10px;
  }

  .footer_News button {
    background: #e39608;
    border: none;
    padding: 20px 30px;
    border-radius: 5px;
    width: 80%;
  }

  .footer .foot {
    display: flex;
    flex-direction: column;
  }

  span {
    background-color: #000;
    height: 1px;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    opacity: 0.2;
  }

  .foot .socials a {
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .footer_News input {
    width: 95%;
  }

  .footer_News button {
    width: 95%;
  }

  .footer_News h4 {
    font-size: 1.4rem !important;
    line-height: 1.2353;
  }

  .copyright {
    flex-direction: column;
    gap: 0.25rem;
  }

  .why_Text p {
    font-size: 0.919rem;
  }

  .why_Text hr {
    width: 20%;
    height: 3px;
    margin-top: 1rem;
  }
}

.content_Container {
  width: 100%;
  margin-top: 8rem;
  margin-bottom: 5rem;
}

.content_Wrapper {
  width: 85%;
  margin: 0 auto;
  /* background-color: #9a9292; */
}

.content_Service {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.content_Card {
  width: 50%;
}

.content_Card h2 {
  font-size: 2.7rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.content_Card p {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  line-height: 28px;
  margin-top: 2rem;
}

.content_Service img,
.content_Services {
  width: 50%;
}

@media screen and (max-width: 1440px) {
  .content_Wrapper {
    width: 95%;
  }
}

@media screen and (max-width: 1000px) {
  .content_Service {
    gap: 1.5rem;
  }

  .content_Card h2 {
    font-size: 2.4rem;
  }

  .content_Service {
    margin-top: 2rem;
  }

  .content_Service img {
    width: 60%;
  }
}

@media screen and (max-width: 800px) {
  .content_Service {
    display: flex;
    flex-direction: column;
  }

  .content_Card {
    width: 100%;
  }

  .content_Card h2 {
    font-size: 2rem;
  }

  .content_Card p {
    font-size: 0.919rem;
  }

  .content_Container img {
    width: 100%;
  }
}
